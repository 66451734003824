<template>
    <div>
        <div class="box content">
            <p v-html="content.what_type_is_right"></p>

            <div class="level">
                <div class="level-item">
                    <router-link
                            tag="button"
                            class="button is-primary is-medium hvr-shadow-radial"
                            style="width: 250px;"
                            :to="{ name: 'getQuote' }"
                    >
                        GET A QUOTE
                    </router-link>
                </div>
            </div>
        </div>

        <div class="box content">
            <h2>Frequently Asked Questions</h2>

            <p>We've put together simple answers to questions frequently asked about our travel insurance.</p>

            <b-collapse class="card" :open="false" v-for="i in 5" :key="i">
                <!-- Question -->
                <div slot="trigger" slot-scope="props" class="card-header" role="button">
                    <p class="card-header-title" v-html="content[`faq_q${i}`]"/>
                    <a class="card-header-icon" rel="noopener">
                        READ MORE... <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"/>
                    </a>
                </div>
                <!-- Answer -->
                <div class="card-content">
                    <div v-html="content[`faq_a${i}`]"/>
                </div>
            </b-collapse>

            <div class="level">
                <div class="level-item">
                    <router-link
                            tag="button"
                            class="button is-primary is-medium hvr-shadow-radial"
                            style="width: 250px; margin-top: 1.5rem;"
                            :to="{ name: 'getQuote' }"
                    >
                        GET A QUOTE
                    </router-link>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
    export default {
        name: "PolicyTypeInformationBox",
        data() {
            return {
                questions: [1, 2, 4, 5]
            }
        },
        props: ['content']
    }
</script>

<style lang="scss" scoped>
    .content /deep/ {
        h1, h2, h3, h4 {
            color: $c2g_blue !important;
        }
        a {
            color: $c2g_blue;
        }
        strong {
            color: $c2g_blue;
        }
        // Making each <li> a tick icon
        li {
            position: relative;
            list-style-type: none;
        }
        li:before {
            content: "";
            position: absolute;
            top: 3px;
            left: -30px;
            width: 20px;
            height: 20px;
            background-image: url('../../assets/images/icons/bullet-tick-sm.png');
        }
        ul {
            margin-top: 5px;
        }
        .card-header-icon {
            color: $c2g_orange;
        }
    }
    .box {
        margin: 20px 0px 20px 0px;
        padding: 50px;
        background-color: $gray1;
    }
    .title {
        color: $c2g_blue;
        margin-bottom: 8px;
    }
    .card-header-title {
        margin-bottom: 0 !important;
    }
    .card {
        margin-top: 10px;
    }
</style>