<template>
    <div>
        <home-top-banner-new/>
        <home-award-logos />

        <!-- Carousel -->
        <transition appear enter-active-class="animated fadeIn fast">
            <!-- <product-carousel :content="content"/> -->
            <product-list/>
        </transition>

        <home-mid-promo-banner/>

        <!-- Travel Insurance Info Content -->
        <transition appear enter-active-class="animated fadeIn fast">
            <information-box :content="content"/>
        </transition>

        <!-- Travel Insurance Covers Content -->
        <transition appear enter-active-class="animated fadeIn fast">
            <travel-insurance-covers/>
        </transition>

        <!-- Policy Type Info Content -->
        <transition appear enter-active-class="animated fadeIn fast">
            <policy-type-information-box :content="content"/>
        </transition>

        <!-- Info boxes -->
        <transition appear enter-active-class="animated fadeIn fast">
            <info-boxes/>
        </transition>

        <div class="footer-text">
            <p>* Please Remember - There is <strong>no cover</strong> on any of our policies if you travel against the Foreign, Commonwealth and Development Office (FCDO), government, local authority, or medical advice. Terms and Conditions apply. Please see our <router-link :to="{ name: 'policyWordingsMUL' }">Policy Wording</router-link> for full details.</p>
        </div>
    </div>
</template>

<script>
    import ProductList from "@/components/home/product-carousel/ProductList";
    import HomeTopBannerNew from "@/components/home/HomeTopBannerNew";
    import HomeAwardLogos from "@/components/home/HomeAwardLogos";
    import HomeMidPromoBanner from "@/components/home/HomeMidPromoBanner";
    import InformationBox from "../components/home/InformationBox";
    import PolicyTypeInformationBox from "../components/home/PolicyTypeInformationBox";
    import InfoBoxes from "@/components/common/InfoBoxes";
    import TravelInsuranceCovers from "@/components/home/TravelInsuranceCovers";
    import { contentAPI } from "@/mixins/content-api";
    import { page } from "@/mixins/page";
    import { mapActions, mapGetters } from "vuex";

    export default {
        name: "Home",
        mixins: [ contentAPI, page ],
        methods: {
            ...mapActions({
                setCallCentre: 'setCallCentre',
                setPolicy: 'setPolicy',
                setReqPolicy: 'setReqPolicy',
                setUpsellPolicy: 'setUpsellPolicy',
                setAffiliation: 'setAffiliation',
            }),
        },
        computed: {
            ...mapGetters({
                callCentre: 'callCentre',
                affiliation: 'affiliation',
                profile: 'profile',
                source: 'source',
            })
        },
        components: {
            ProductList,
            InfoBoxes,
            InformationBox,
            PolicyTypeInformationBox,
            HomeTopBannerNew,
            HomeAwardLogos,
            HomeMidPromoBanner,
            TravelInsuranceCovers
        },
        created() {
            // CSC profile setting
            if(this.$route.query.csc !== undefined) {
                this.setCallCentre(this.$route.query.profile);
                // Display message
                this.$buefy.toast.open({
                    duration: 4000,
                    message: `CSC set to ${this.callCentre}`,
                    position: 'is-bottom'
                })
            }

            // Reset the policy
            this.setPolicy(null);
            this.setReqPolicy(null);
            this.setUpsellPolicy(null);

            if (this.$route.query.source != undefined){
                this.setAffiliation(this.$route.query.source);
            } else if (this.$route.query.profile != undefined){
                this.setAffiliation(this.$route.query.profile)
            } else if (this.$route.query.affiliate != undefined){
                this.setAffiliation(this.$route.query.affiliate)
            }
        }
    }
</script>

<style lang="scss" scoped>
    #top-message {
        text-align: center;
        padding: 10px 0 20px 0;
        #first-message {
            font-size: 20px;
            font-weight: bold;
            color: $c2g_blue;
        }
        #second-message {
            font-size: 32px;
            font-weight: bold;
            color: $c2g_blue;
        }
    }

    #alert-important {
        margin-bottom: 15px;
    }

    .footer-text {
        margin-top: 40px;
        text-align: center;
    }
</style>